<template>
  <div>
    <main class="container">
      <div class="section">
        <h1>Cash Flow & Forecasting</h1>
        <p>Welcome to your personalised cash flow forecast.</p>
      </div>
      <div class="section" v-if="shortTermCashFlowData">
        <h2>Short Term Cash Flow</h2>
        <div class="section-card-list">
          <div class="card">
            <span>Cash available</span>
            <h3>{{ shortTermCashFlowData.cashToday | commarize | currency }}</h3>
            <span>As of {{ shortTermCashFlowData.lastAvailableDate | dateFormat }}</span>
          </div>
          <div class="card">
            <span>Invoices</span>
            <h3>{{ shortTermCashFlowData.invoices | commarize | currency }}</h3>
            <span v-if="shortTermCashFlowData.invoiceNumber">{{ shortTermCashFlowData.invoiceNumber }} Invoices</span>
          </div>
          <div class="card">
            <span>Bills</span>
            <h3>{{ shortTermCashFlowData.bills | commarize | currency }}</h3>
            <span v-if="shortTermCashFlowData.billsNumber">{{ shortTermCashFlowData.billsNumber }} Bills</span>
          </div>
        </div>
      </div>
      <div class="section" v-if="bar">
        <div class="card">
          <div class="card-header">
            <div class="card-title">
              <h3>Actual & Projected Cash Flow</h3>
              <div class="vertical-break"></div>
              <div class="legend">
                <span class="cash-in">Cash in</span>
                <span class="cash-out">Cash out</span>
              </div>
            </div>
            <div class="card-options">
              <div class="card-option">
                <label>History:</label>
                <select v-model="selectedHistory">
                  <option v-for="option in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]" :value="option" :key="option">{{ option }} Months</option>
                </select>
              </div>
              <div class="card-option">
                <label>Forecast:</label>
                <select v-model="selectedForecast">
                  <option v-for="option in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]" :value="option" :key="option">{{ option }} Months</option>
                </select>
              </div>
            </div>
          </div>
          <v-chart class="chart" :option="bar" ref="bar" theme="ovilia-green" autoresize :loading="barLoading" :loadingOptions="barLoadingOptions" />
          <div class="mobile-chart-controls">
            <button @click="previousMonth" :disabled="currentMonthIndex === 0">
              &lt;
            </button>
            <button @click="nextMonth" :disabled="currentMonthIndex === cashflowForecastData.length - 1">
              &gt;
            </button>
          </div>
        </div>
      </div>
      <div class="section" v-if="cashflowForecastData">
        <div class="card">
          <h3>Cash Flow Actual & Projection</h3>
          <div class="table-container">
            <table>
              <thead>
                <tr>
                  <td></td>
                  <td v-for="(item, index) in cashflowForecastData" :key="index">{{ getMonthName(item.month) + ' ' + String(item.year).slice(2) }}</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Opening Balance</td>
                  <td v-for="(item, index) in cashflowForecastData" :key="index">{{ item.openingBalance | commarize | currency }}</td>
                </tr>
                <tr>
                  <td class="td--dark">CASH IN</td>
                  <td v-for="(item, index) in cashflowForecastData" :key="index">{{ item.cashIn.total | commarize | currency }}</td>
                </tr>
                <tr>
                  <td>Invoices</td>
                  <td v-for="(item, index) in cashflowForecastData" :key="index">{{ item.cashIn.invoice | commarize | currency }}</td>
                </tr>
                <tr>
                  <td>Income</td>
                  <td v-for="(item, index) in cashflowForecastData" :key="index">{{ item.cashIn.income | commarize | currency }}</td>
                </tr>
                <tr>
                  <td>Other</td>
                  <td v-for="(item, index) in cashflowForecastData" :key="index">{{ item.cashIn.other | commarize | currency }}</td>
                </tr>
                <tr>
                  <td class="td--dark">CASH OUT</td>
                  <td v-for="(item, index) in cashflowForecastData" :key="index">{{ item.cashOut.total | commarize | currency }}</td>
                </tr>
                <tr>
                  <td>
                    Bills
                  </td>
                  <td v-for="(item, index) in cashflowForecastData" :key="index">{{ item.cashOut.bills | commarize | currency }}</td>
                </tr>
                <tr>
                  <td>
                    Expenses
                  </td>
                  <td v-for="(item, index) in cashflowForecastData" :key="index">{{ item.cashOut.expenses | commarize | currency }}</td>
                </tr>
                <tr>
                  <td>
                    Other
                  </td>
                  <td v-for="(item, index) in cashflowForecastData" :key="index">{{ item.cashOut.other | commarize | currency }}</td>
                </tr>
                <tr>
                  <td>
                    Closing Balance
                  </td>
                  <td v-for="(item, index) in cashflowForecastData" :key="index">{{ item.closeBalance | commarize | currency }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { BarChart } from 'echarts/charts'
import { use } from 'echarts/core'
import Vue from 'vue'
import 'echarts'
import { getShortTermCashflow, getCashflowForecast } from '@/api/forwardAI'
import VueCompositionAPI from '@vue/composition-api'
import { CanvasRenderer } from 'echarts/renderers'
import { TitleComponent, TooltipComponent, LegendComponent, GridComponent } from 'echarts/components'
import { mapGetters } from 'vuex'
import { companyGetters } from '@/store/modules/company/routines'
import { authGetters } from '@/store/modules/auth/routines'
import VChart, { THEME_KEY } from 'vue-echarts'
Vue.use(VueCompositionAPI)

use([BarChart, TitleComponent, GridComponent, CanvasRenderer, TooltipComponent, LegendComponent])

export default {
  components: {
    VChart
  },
  provide: {
    [THEME_KEY]: 'dark'
  },
  mounted() {
    getShortTermCashflow(this.companyId).then(({ data }) => {
      this.shortTermCashFlowData = data
    })
    getCashflowForecast(this.companyId, this.selectedHistory, this.selectedForecast).then(({ data }) => {
      this.cashflowForecastData = data
      this.bar = this.getBar(data)
    })
    window.addEventListener('resize', event => {
      this.width = window.innerWidth
    })
  },
  unmounted() {
    window.removeEventListener('resize', this.width)
  },
  data() {
    return {
      shortTermCashFlowData: null,
      cashflowForecastData: null,
      selectedHistory: 12,
      selectedForecast: 12,
      width: window.innerWidth,
      currentMonthIndex: 0,
      bar: null,
      barLoading: false,
      barLoadingOptions: {
        text: 'Loading…',
        color: '#4ea397',
        maskColor: 'rgba(255, 255, 255, 0.4)'
      },
      initOptions: {
        renderer: 'canvas'
      }
    }
  },
  computed: {
    ...mapGetters({
      companyId: companyGetters.COMPANY_ID,
      user: authGetters.USER
    })
  },
  methods: {
    getBar(cashflowForecastData) {
      return {
        textStyle: {
          fontFamily: 'Inter, "Helvetica Neue", Arial, sans-serif'
        },
        grid: {
          left: 40,
          right: 40,
          top: 40,
          bottom: 60
        },
        dataset: {
          dimensions: ['date', 'cash in', 'cash out'],
          source: cashflowForecastData?.map(item => ({
            date: this.getMonthName(item.month) + ' ' + String(item.year).slice(2),
            'cash in': item.cashIn.total,
            'cash out': item.cashOut.total
          }))
        },
        xAxis: { type: 'category' },
        yAxis: {},
        series: [
          { type: 'bar', color: '#2E9C8E' },
          { type: 'bar', color: '#003E52' }
        ]
      }
    },
    getMonthName(monthNumber) {
      const date = new Date()
      date.setMonth(monthNumber - 1)
      return date.toLocaleString([], { month: 'short' })
    },
    generateForecast() {
      getCashflowForecast(this.companyId, this.selectedHistory, this.selectedForecast).then(({ data }) => {
        this.cashflowForecastData = data
        this.bar = this.getBar(data)
      })
    },
    nextMonth() {
      this.currentMonthIndex = this.currentMonthIndex + 1
    },
    previousMonth() {
      this.currentMonthIndex = this.currentMonthIndex - 1
    }
  },
  watch: {
    selectedForecast() {
      this.generateForecast()
    },
    selectedHistory() {
      this.generateForecast()
    },
    width(width) {
      if (width <= 520) {
        this.bar = this.getBar([this.cashflowForecastData[0]])
      } else {
        this.bar = this.getBar(this.cashflowForecastData)
      }
    },
    currentMonthIndex(currentMonthIndex) {
      this.bar = this.getBar([this.cashflowForecastData[currentMonthIndex]])
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

.chart {
  width: 100%;
  height: 100%;
}

main.container {
  max-width: 1210px;
  height: 100%;
  width: 100%;

  .section {
    margin: 48px 0;

    h2 {
      display: flex;
      align-items: center;
      &::before {
        content: '';
        display: inline-block;
        width: 4px;
        height: 29px;
        margin-right: 8px;
        border-radius: 32px;
        background-color: #2e9c8e;
      }
    }

    h3 {
      margin: 0;
    }
    .card {
      height: 100%;
      padding: 33px;
      border-radius: 12px;
      box-shadow: 0px 0px 1px rgba(27, 103, 152, 0.15018), 0px 1px 3px rgba(27, 103, 152, 0.150131);

      .card-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media only screen and (max-width: 1350px) {
          flex-direction: column;
          align-items: flex-start;
        }

        @media only screen and (max-width: 520px) {
          .vertical-break {
            display: none;
          }

          .legend {
            width: 100%;
          }
        }

        .card-title {
          display: flex;
          align-items: center;
          white-space: nowrap;

          @media only screen and (max-width: 520px) {
            flex-direction: column;
          }
        }

        .card-options {
          display: flex;
          align-items: center;

          @media only screen and (max-width: 520px) {
            display: none;
          }

          .card-option {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 16px;

            &:last-of-type {
              margin-right: 0;
            }
          }

          select {
            margin-left: 6px;
            width: 110px;
            border: none;
            color: $color-main;
            background: transparent
              url("data:image/svg+xml,%3Csvg width='7' height='5' viewBox='0 0 7 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.39258 0.527344L3.5 2.63477L5.60742 0.527344C5.81055 0.324219 6.16602 0.324219 6.36914 0.527344C6.57227 0.755859 6.57227 1.08594 6.36914 1.28906L3.88086 3.77734C3.67773 4.00586 3.32227 4.00586 3.11914 3.77734L0.630859 1.28906C0.427734 1.08594 0.427734 0.755859 0.630859 0.527344C0.833984 0.324219 1.18945 0.324219 1.39258 0.527344Z' fill='%23336575'/%3E%3C/svg%3E%0A")
              no-repeat;
            background-position: right 18px top 50%;
            text-align: left;
            right: auto;
            option {
              direction: rtl;
            }
          }
        }
      }

      .vertical-break {
        width: 21px;
        height: 0px;
        margin: 0 16px;

        border: 1px solid #dee8ec;
        transform: rotate(90deg);
      }
    }

    .section-card-list {
      display: flex;
      justify-content: space-between;

      @media only screen and (max-width: 600px) {
        flex-direction: column;

        .card {
          margin-top: 24px;

          &:first-of-type {
            margin-top: 0;
          }
        }
      }

      .card {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-right: 24px;
        height: 150px;

        &:last-of-type {
          margin-right: 0;
        }

        h3 {
          margin: 3px 0;
        }

        span {
          font-size: 14px;
        }
      }
    }

    &:nth-of-type(3) {
      height: 590px;
      position: relative;

      .legend {
        span::after {
          content: '';
          display: inline-block;
          width: 9px;
          height: 9px;
          margin-left: 8px;
          -moz-border-radius: 7.5px;
          -webkit-border-radius: 7.5px;
          border-radius: 7.5px;
          background-color: #2e9c8e;
        }

        .cash-in {
          color: #2e9c8e;
          margin-right: 16px;
        }
        .cash-out {
          color: #003e52;

          &::after {
            background-color: #003e52;
          }
        }
      }

      .mobile-chart-controls {
        position: absolute;
        bottom: 25px;
        width: 100%;
        left: 0;
        display: none;
        justify-content: space-around;

        button {
          border-radius: 50%;
          border-style: none;
          width: 16px;
          height: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 700;
          font-size: 12px;
          background: #ffffff;
          box-shadow: 0px 0px 3px 3px rgba(0, 31, 41, 0.06), 0px 1px 3px rgba(0, 31, 41, 0.1);
        }

        @media only screen and (max-width: 520px) {
          display: flex;
        }
      }
    }

    &:nth-of-type(4) {
      height: 590px;
    }

    .table-container {
      width: 100%;
      height: 100%;
      overflow-x: scroll;
      table {
        width: 100%;
        height: calc(100% - 33px);
        border-top: 1px solid #dee8ec;
        margin-top: 20px;
        position: relative;

        tr {
          position: relative;
          font-weight: 700;
          font-size: 10px;

          td {
            padding: 8px;
            white-space: nowrap;

            &:first-of-type {
              background-color: #ffffff;
              position: sticky;
              left: 0;
              top: 0;
            }

            &.td--dark {
              background-color: #f4f8fa;
            }
          }
        }

        thead {
          tr {
            color: #668b97;
          }
        }

        tbody tr {
          &:nth-of-type(1) {
            color: #003e52;
            font-weight: 700;
          }
          &:nth-of-type(2) {
            color: #003e52;
            background-color: #f4f8fa;
            font-weight: 700;
          }
          &:nth-of-type(6) {
            color: #003e52;
            background-color: #f4f8fa;
            font-weight: 700;
          }
          &:last-of-type {
            color: #003e52;
            font-weight: 700;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1450px) {
    padding: 0 3rem;
  }

  @media only screen and (max-width: 800px) {
    padding: 0 1rem;
  }
}
</style>
